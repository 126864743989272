import React from 'react';
import MarioImage from '../assets/mario.jpeg';

const Projects = () => {
  return (
    <div className="container py-4">
      <h2>Our Projects</h2>
      <p>This is the Our Projects page content.</p>

      <div className="row">
        <div className="col-md-6">
          <img src={MarioImage} className="img-fluid" alt="Placeholder" />
        </div>
        <div className="col-md-6">
          <h3>Recent Projects</h3>
          <ul>
            <li>Project A</li>
            <li>Project B</li>
            <li>Project C</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Projects;
