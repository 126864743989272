import React from 'react';
import MarioImage from '../assets/mario.jpeg';

const AboutUs = () => {
  return (
    <div className="container py-4">
      <h2>About Us</h2>
      <p>This is the About Us page content.</p>

      <div className="row">
        <div className="col-md-6">
          <img src={MarioImage} className="img-fluid" alt="Placeholder" />
        </div>
        <div className="col-md-6">
          <h3>Our Team</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
          <p>Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
