import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import OurService from './components/OurService';
import Projects from './components/Projects';
import Apod from './components/Apod'; 
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/service" element={<OurService />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/apod" element={<Apod />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
