import React from 'react';

const Footer = () => {
  return (
    <footer className="text-white text-center py-4">
      <p>© 2024 My Website. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
