import React from 'react';
import MarioImage from '../assets/mario.jpeg';

const ContactUs = () => {
  return (
    <div className="container py-4">
      <h2>Contact Us</h2>
      <p>This is the Contact Us page content.</p>

      <div className="row">
        <div className="col-md-6">
          <img src={MarioImage} className="img-fluid" alt="Placeholder" />
        </div>
        <div className="col-md-6">
          <h3>Contact Information</h3>
          <p>Email: example@example.com</p>
          <p>Phone: 123-456-7890</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
